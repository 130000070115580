modules.define('mod_videos', function ($) {
    return {
        init: function () {
            $(document).on('click', '.js_play-video', function () {
                $modal_target = $(this).data('modalid');
                var $videoModal = $($modal_target);
                console.log($modal_target)
                    //$videoLink = $(this);
              //      src = $videoLink.data('source');
               // $videoModal.find('iframe').get(0).src = src;
                $videoModal.show();
            });
            
            $(document).on('click', '.js_close-video-modal', function () {
                var $videoModal = $(this).closest(".video-modal");
                var ifr = $videoModal.find('iframe');
                var ifr_clone = $videoModal.find('iframe').clone();
                ifr.replaceWith(ifr_clone)
          //      $videoModal.find('iframe').get(0).src = "";
          
                $videoModal.hide();
            });
        },
    }
}, jQuery);