modules.define('main_menu', function ($) {
    return {
        init: function () {
            if (helpers.viewport().width > 1024) {
                $('.dropdown').hover(
                    function () {
                        $(this).addClass('open');
                    },
                    function () {
                        $(this).removeClass('open');
                    }
                );
            }
    
            $('.subcategories > li').hover(
                function () {
                    var target = $(this).children('a').first().data('hover-target'),
                        $activeGroup = $(".cl-item-group:visible"),
                        $target = $(target);
                    
                    $activeGroup.hide();
                    $target.fadeIn(50);
                }
            );
        }
    }
}, jQuery);