modules.define('slide_to_link', function($) {
    return {    
        init: function() {
            $(document).on('click', '.slide-to-link', function () {
                var $link = $(this),
                    target = $link.attr('href'),
                    $target = $(target);
                $("html, body").animate({
                    scrollTop: $target.offset().top
                }, "slow");
            });
        }
    }
}, jQuery);