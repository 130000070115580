modules.define('tab_links_custom', function($) {
    return {    
        init: function() {
            $('.tab-links-custom').on('click', 'a', function() {
                var $link = $(this),
                    target = $link.attr('href');
                var $stdLink = $("#production-tabbed-content").find("a[href='"+target+"']");
                $stdLink.parents('.nav-tabs').find('li').removeClass('active');
                $stdLink.parent('li').addClass('active');
            });
        }
    }
}, jQuery);